import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'
import './filters.js'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// Config axios
Vue.prototype.$axios = axios.create({
  baseURL: 'https://api.the-netwerk.com/api/',
})

Vue.mixin({
  methods: {
    checkAuth() {
      const token = localStorage.getItem('token')
      if (token === null) {
        location.href = '/login'
      }
    },
    createToast(type, header, message) {
      this.$bvToast.toast(message, {
        title: header,
        variant: type,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 3000,
      })
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i += 1) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
