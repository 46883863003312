import axios from '@/libs/axios'

// context.rootState.config call root state in store/index.js

export default {
  loadCorporate: context => {
    axios.get('/corporate', context.rootState.config).then(res => {
      const { data } = res.data
      const corporate = []

      data.corporate.forEach(item => {
        const contact = item.contact_name === null ? '-' : item.contact_name
        const phoneCode = item.corporate_phonecode === null ? '' : item.corporate_phonecode
        const phone = `${phoneCode} ${item.corporate_phone}`

        const targetMember = item.corporate_total_member
        const totalMember = item.total_member_av

        const corporateItem = {
          id: item.id,
          name: item.corporate_name,
          logo: item.logo,
          contact,
          phone,
          targetMember,
          totalMember,
          member_text: `${totalMember} of ${targetMember} Members`,
          expired_date: item.expired_date,
        }

        corporate.push(corporateItem)
      })

      const corporateData = {
        corporate,
        corporate_active: data.corporate_active,
        corporate_inactive: data.corporate_inactive,
        corporate_exp: data.corporate_exp,
        corporate_limit: data.corporate_limit,
      }
      context.commit('loadCorporate', corporateData)
    })
  },
  loadDetailCorporate: (context, id) => {
    axios.get(`/corporate/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadDetailCorporate', data)
      context.commit('loadCorporateMembers', data.member)
    })
  },
  loadSocmedCorporate: (context, id) => {
    axios.get(`/member/corporate/socialmedia/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadSocmedCorporate', data)
    })
  },
  loadAddressCorporate: (context, id) => {
    axios.get(`/member/corporate/address/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadAddressCorporate', data)
    })
  },
  loadBannerCorporate: (context, id) => {
    axios.get(`/banner/corporate/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadBannerCorporate', data)
    })
  },
  loadPartnerCorporate: (context, id) => {
    axios.get(`/partner/corporate/${id}`, context.rootState.config).then(res => {
      const data = res.data.data.partner
      context.commit('loadPartnerCorporate', data)
    })
  },
  loadEventCorporate: (context, id) => {
    axios.get(`/event/corporate/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadEventCorporate', data)
    })
  },

  // corporate socmed actions
  loadDetailSocmed: (context, id) => {
    axios.get(`/member/corporate/socialmedia/id/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadDetailSocmed', data)
    })
  },
  addSocmedCorporate: (context, data) => new Promise((resolve, reject) => {
    axios
      .post('/member/corporate/socialmedia', data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  editSocmedCorporate: (context, data) => new Promise((resolve, reject) => {
    axios
      .put(`/member/corporate/socialmedia/${data.id}`, data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  deleteSocmedCorporate: (context, id) => new Promise((resolve, reject) => {
    axios
      .delete(`/member/corporate/socialmedia/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  resetCorporateSocmed: context => {
    context.commit('resetCorporateSocmed')
  },

  // corporate address actions
  loadDetailAddress: (context, id) => {
    axios.get(`/member/corporate/address/id/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadDetailAddress', data)
    })
  },
  addAddressCorporate: (context, data) => new Promise((resolve, reject) => {
    const address = {
      corporate_id: data.corporate_id,
      corporate_address: data.address,
      corporate_country: data.country,
      corporate_state: data.state,
      corporate_city: data.city,
      corporate_postal_code: data.postal_code,
      set_default: data.default ? 'Yes' : null,
      order: data.order,
    }

    axios
      .post('/member/corporate/address', address, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  editAddressCorporate: (context, data) => {
    const address = {
      corporate_address: data.address,
      corporate_country: data.country,
      corporate_state: data.state,
      corporate_city: data.city,
      corporate_postal_code: data.postal_code,
      set_default: data.default ? 'Yes' : null,
      order: data.order,
    }

    return new Promise((resolve, reject) => {
      axios
        .put(`/member/corporate/address/${data.id}`, address, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  resetCorporateAddress: context => {
    context.commit('resetCorporateAddress')
  },
}
