export default {
  corporate: [],
  corporate_active: 0,
  corporate_inactive: 0,
  corporate_exp: 0,
  corporate_limit: 0,
  corporate_detail: {
    name: null,
    contact_name: null,
    phone: null,
    phone_code: null,
    telephone: null,
    telephone_code: null,
    npwp: null,
    description: null,
    commerce_group: null,
    website: null,
    capacity: null,
    logo: null,
    background: null,
    pdf: null,
  },
  corporate_members: [],
  corporate_socmed: [],
  corporate_socmed_detail: {
    id: null,
    corporate_id: null,
    name: null,
    title: null,
    link: null,
  },
  corporate_address: [],
  corporate_address_detail: {
    id: null,
    corporate_id: null,
    address: null,
    country: null,
    state: null,
    city: null,
    postal_code: null,
    order: null,
    default: null,
  },
  corporate_banner: [],
  corporate_partner: [],
  corporate_event: [],
  corporate_role: [],
}
