export default {
  age: null,
  business_cat: null,
  education: null,
  man_member: null,
  occupation: null,
  provider: null,
  smartphone: null,
  ses: null,
  tech_savvy: null,
  woman_member: null,
  corporate: null,
  dataFilter: {
    corporate_id: null,
    start_date: null,
    end_date: null,
  },
}
