export default {
  loadCountries: (state, data) => {
    const countries = data.map(item => ({
      value: item.id,
      text: item.name,
      phonecode: item.phonecode,
    }))

    state.countries = countries
    state.states = []
    state.cities = []
  },
  loadStates: (state, data) => {
    const states = data.map(item => ({
      value: item.id,
      text: item.name,
    }))

    state.states = states
    state.cities = []
  },
  loadCities: (state, data) => {
    const city = data.map(item => ({
      value: item.id,
      text: item.name,
    }))

    state.cities = city
  },

  resetState: state => {
    state.states = []
  },
  resetCity: state => {
    state.cities = []
  },
}
