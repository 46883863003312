export default {
  loadEvent: (state, data) => {
    state.event = data
  },

  loadEventDetail: (state, data) => {
    const { detail } = state

    detail.id = data.id
    detail.name = data.name
    detail.start_date = data.start_date
    detail.start_date_raw = data.start_date_raw
    detail.end_date = data.end_date
    detail.end_date_raw = data.end_date_raw
    detail.description = data.description
    detail.created_by = data.created_by
    detail.location = data.location
    detail.thumbnail = data.image
    detail.file.url = data.image
    detail.status = data.status
  },

  loadEventLog: (state, data) => {
    state.log = data
  },

  loadEventParticipants: (state, data) => {
    state.participants = data
  },
  resetEvent: state => {
    const { detail } = state

    detail.id = null
    detail.name = null
    detail.start_date = null
    detail.start_date_raw = null
    detail.end_date = null
    detail.end_date_raw = null
    detail.description = null
    detail.created_by = null
    detail.location = null
    detail.thumbnail = null
    detail.status = null
    detail.file = {
      url: null,
      image: null,
    }
    detail.file_raw = null
  },
}
