export default {
  loadCorporate: (state, data) => {
    state.corporate = data.corporate
    state.corporate_active = data.corporate_active
    state.corporate_inactive = data.corporate_inactive
    state.corporate_exp = data.corporate_exp
    state.corporate_limit = data.corporate_limit
  },
  loadDetailCorporate: (state, data) => {
    const detail = state.corporate_detail
    const { corporate } = data

    detail.name = corporate.corporate_name
    detail.contact_name = corporate.contact_name
    detail.phone = corporate.corporate_phone
    detail.phone_code = corporate.corporate_phonecode
    detail.telephone = corporate.corporate_telephone
    detail.telephone_code = corporate.corporate_telephonecode
    detail.npwp = corporate.corporate_npwp
    detail.description = corporate.corporate_description
    detail.commerce_group = corporate.commerce_group
    detail.website = corporate.corporate_website
    detail.capacity = corporate.corporate_total_member
  },
  loadCorporateMembers: (state, data) => {
    const result = data.data.map(item => {
      let phone = '-'
      if (item.member_phone) {
        phone = `(${(item.phone_code) ? item.phone_code : '62'}) ${item.member_phone}`
      }

      return {
        id: item.id,
        user_id: item.user_id,
        name: item.name,
        email: item.email,
        status: item.status,
        logo: item.logo,
        phone,
        divisi: item.divisi,
      }
    })

    state.corporate_members = result
  },

  loadSocmedCorporate: (state, data) => {
    state.corporate_socmed = data
  },
  loadDetailSocmed: (state, data) => {
    const detail = state.corporate_socmed_detail

    detail.id = data.id
    detail.corporate_id = data.corporate_id
    detail.name = data.name
    detail.title = data.title
    detail.link = data.link
  },
  resetCorporateSocmed: state => {
    const detail = state.corporate_socmed_detail

    detail.id = null
    detail.corporate_id = null
    detail.name = null
    detail.title = null
    detail.link = null
  },

  loadAddressCorporate: (state, data) => {
    const address = data.map(item => {
      const country = (item.country) ? item.country.name : '-'
      const states = (item.state) ? item.state.name : '-'
      const city = (item.city) ? item.city.name : '-'

      return {
        id: item.id,
        address: item.corporate_address,
        country,
        states,
        city,
        postal_code: item.corporate_postal_code,
        order: item.order,
      }
    })

    state.corporate_address = address
  },
  loadDetailAddress: (state, data) => {
    const detail = state.corporate_address_detail

    detail.id = data.id
    detail.corporate_id = data.corporate_id
    detail.address = data.corporate_address
    detail.country = data.corporate_country
    detail.state = data.corporate_state
    detail.city = data.corporate_city
    detail.postal_code = data.corporate_postal_code
    detail.order = data.order

    if (data.corporate_address_default == null || data.corporate_address_default === 'No') {
      detail.default = false
    } else {
      detail.default = true
    }
  },
  resetCorporateAddress: state => {
    const detail = state.corporate_address_detail

    detail.id = null
    detail.corporate_id = null
    detail.address = null
    detail.country = null
    detail.state = null
    detail.city = null
    detail.postal_code = null
    detail.order = null
    detail.default = null
  },

  loadBannerCorporate: (state, data) => {
    state.corporate_banner = data
  },
  loadPartnerCorporate: (state, data) => {
    state.corporate_partner = data
  },
  loadEventCorporate: (state, data) => {
    state.corporate_event = data
  },
  loadRoleCorporate: (state, data) => {
    state.corporate_role = data
  },
}
