import axios from '@/libs/axios'

// context.rootState.config call root state in store/index.js

export default {
  loadEventCorporate: context => {
    axios.get('/event', context.rootState.config)
      .then(res => {
        const { data } = res.data
        const events = []

        data.forEach(item => {
          const event = {
            id: item.id,
            name: item.name,
            image: item.image,
            start_date: item.start_date.replace(' | ', ' '),
            end_date: item.end_date.replace(' | ', ' '),
            created_by: item.created_by,
            location: item.location,
            status: item.status,
          }

          events.push(event)
        })

        context.commit('loadEvent', events)
      })
  },
  loadEventAll: (context, status = 'all') => {
    let statusParam = ''
    if (status !== 'all') {
      statusParam = `?status=${status}`
    }

    axios.get(`/event/all${statusParam}`, context.rootState.config)
      .then(res => {
        const { data } = res.data
        const events = []

        if (data.length > 0) {
          data.forEach(item => {
            const event = {
              id: item.id,
              name: item.name,
              image: item.image,
              start_date: item.start_date.replace(' | ', ' '),
              end_date: item.end_date.replace(' | ', ' '),
              created_by: item.created_by,
              location: item.location,
              status: item.status,
              corporate: item.corporate,
            }

            events.push(event)
          })
        }

        context.commit('loadEvent', events)
      })
  },
  loadDetailEvent: (context, id) => {
    axios.get(`/event/${id}?participant=1`, context.rootState.config)
      .then(res => {
        const { data } = res.data

        context.commit('loadEventDetail', data)
        context.commit('loadEventParticipants', data.participants)
      })
  },
  loadLogEvent: context => {
    axios.get('/log/event', context.rootState.config)
      .then(res => {
        const { data } = res.data
        const events = []

        data.forEach(item => {
          const event = {
            id: item.id,
            name: item.name,
            image: item.image,
            start_date: item.start_date.replace(' | ', ' '),
            end_date: item.end_date.replace(' | ', ' '),
            created_by: item.created_by,
            location: item.location,
            status: item.status,
          }

          events.push(event)
        })

        context.commit('loadEventLog', events)
      })
  },
  loadLogEventAll: context => {
    axios.get('/event/all?status=done', context.rootState.config)
      .then(res => {
        const { data } = res.data
        const events = []

        if (data.length > 0) {
          data.forEach(item => {
            const event = {
              id: item.id,
              name: item.name,
              image: item.image,
              start_date: item.start_date.replace(' | ', ' '),
              end_date: item.end_date.replace(' | ', ' '),
              created_by: item.created_by,
              location: item.location,
              status: item.status,
              corporate: item.corporate,
            }

            events.push(event)
          })
        }

        context.commit('loadEventLog', events)
      })
  },
  addEvent: (context, data) => new Promise((resolve, reject) => {
    axios.post('/event', data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  editEvent: (context, payload) => new Promise((resolve, reject) => {
    axios.post(`/event/${payload.id}?_method=PUT`, payload.data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  deleteEvent: (context, id) => new Promise((resolve, reject) => {
    axios.delete(`/event/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  resetEvent: context => {
    context.commit('resetEvent')
  },
}
