import axios from '@/libs/axios'

export default {
  loadRoleCorporate: (context, id) => {
    axios.get(`/role/corporate/${id}`, context.rootState.config)
      .then(res => {
        context.commit('loadRoleCorporate', res.data.data)
      })
  },
  loadDetailRole: (context, id) => {
    axios.get(`/role/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadDetailRole', data)
    })
    axios.get(`/menu/role/${id}`, context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadDetailPermissionRole', data)
    })
  },
  addRole: (context, data) => new Promise((resolve, reject) => {
    axios.post('role', data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  editRole: (context, payload) => {
    const role = {
      name: payload.name,
      access_dashboard: payload.access_dashboard,
    }
    return new Promise((resolve, reject) => {
      axios.put(`role/${payload.id}`, role, context.rootState.config)
        .then(res => resolve(res))
        .catch(error => reject(error))
    })
  },
  deleteRole: (context, id) => new Promise((resolve, reject) => {
    axios.delete(`role/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  addRoleMenu: (context, data) => new Promise((resolve, reject) => {
    axios.post('menu/role', data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
}
