import axios from '@/libs/axios'

export default {
  loadPartner: context => {
    axios.get('/partner', context.rootState.config)
      .then(res => {
        const { data } = res.data

        const partner = data.parent.map(item => {
          let corporateID = null
          let corporateName = null

          if (item.corporate !== null) {
            corporateID = item.corporate.id
            corporateName = item.corporate.name
          }

          return {
            id: item.id,
            name: item.name,
            image: item.image,
            expired: item.is_expired,
            date: item.date,
            pin_code: item.pin_code,
            corporate: corporateName,
            corporateID,
            cabang: item.cabang ? item.cabang.length : 0,
          }
        })

        context.commit('loadPartner', partner)
      })
  },
  loadDetailPartner: (context, id) => {
    axios.get(`/partner/${id}`, context.rootState.config)
      .then(res => {
        const { data } = res.data
        context.commit('loadDetailPartner', data)
      })
  },
  addPartner: (context, data) => new Promise((resolve, reject) => {
    axios.post('partner', data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  editPartner: (context, payload) => new Promise((resolve, reject) => {
    axios.post(`partner/${payload.id}?_method=PUT`, payload.data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  deletePartner: (context, id) => new Promise((resolve, reject) => {
    axios.delete(`/partner/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  resetPartner: context => {
    context.commit('resetPartner')
  },
}
