import axios from '@/libs/axios'

// context.rootState.config call root state in store/index.js

export default {
  loadSurvey: context => {
    axios.get('/survey', context.rootState.config).then(res => {
      const { data } = res.data
      context.commit('loadSurvey', data)
    })
  },
  loadDetailSurvey: (context, id) => new Promise((resolve, reject) => {
    axios.get(`/survey/${id}`, context.rootState.config)
      .then(res => {
        const { data } = res.data
        context.commit('loadDetailSurvey', data)
        resolve(res)
      })
      .catch(error => reject(error))
  }),
  loadConfigSurvey: (context, id) => new Promise((resolve, reject) => {
    axios.get(`/survey/config/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  loadDetailsSurvey: (context, id) => new Promise((resolve, reject) => {
    axios.get(`/survey/answer/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  addSurvey: (context, data) => new Promise((resolve, reject) => {
    axios
      .post('/survey', data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  addConfigSurvey: (context, data) => new Promise((resolve, reject) => {
    axios.post('/survey/config/', data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  editSurvey: (context, data) => new Promise((resolve, reject) => {
    axios
      .put(`/survey/${data.id}`, data, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  deleteSurvey: (context, id) => new Promise((resolve, reject) => {
    axios
      .delete(`/survey/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
  deleteDetail: (context, id) => new Promise((resolve, reject) => {
    axios
      .delete(`/survey/detail/${id}`, context.rootState.config)
      .then(res => resolve(res))
      .catch(error => reject(error))
  }),
}
