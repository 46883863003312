export default {
  event: [],
  log: [],
  detail: {
    id: null,
    name: null,
    start_date: null,
    start_date_raw: null,
    end_date: null,
    end_date_raw: null,
    description: null,
    created_by: null,
    location: null,
    thumbnail: null,
    status: null,
    file: {
      url: null,
      image: null,
    },
    file_raw: null,
  },
  participants: [],
}
