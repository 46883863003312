export default {
  loadPartner: (state, data) => {
    state.partner = data
  },
  loadDetailPartner: (state, data) => {
    const { detail } = state

    const startDateSplit = data.start_date.split('-')
    const endDateSplit = data.end_date.split('-')
    const startDate = `${startDateSplit[2]}-${startDateSplit[1]}-${startDateSplit[0]}`
    const endDate = `${endDateSplit[2]}-${endDateSplit[1]}-${endDateSplit[0]}`
    const rangeDate = `${startDate} to ${endDate}`

    detail.id = data.id
    detail.title = data.name
    detail.description = data.description
    detail.date = rangeDate
    detail.pin_code = data.pin_code
    detail.category = data.category
    detail.logo = data.image
    detail.file.url = data.image
  },
  resetPartner: state => {
    const { detail } = state

    detail.id = null
    detail.title = null
    detail.title_parent = null
    detail.description = null
    detail.date = null
    detail.pin_code = null
    detail.category = null
    detail.has_parent = false
    detail.parent = null
    detail.file = {
      url: null,
      image: null,
    }
    detail.file_raw = null
  },
}
