import Vue from 'vue'

Vue.filter('handleNullValue', value => {
  if (value === null || value === 'null') {
    return '-'
  }
  return value
})

Vue.filter('initial', value => {
  if (value) {
    let result = ''
    const tokens = value.split('')

    if (tokens.length === 1) {
      result = tokens[0].substring(0, 1).toUpperCase()
    } else {
      for (let i = 0; i < 2; i += 1) {
        result += tokens[i].substring(0, 1).toUpperCase()
      }
    }

    return result
  }

  return value
})

Vue.filter('dotConcat', (value, length = 25) => {
  let result = ''

  if (value) {
    const lengthText = value.split('')

    if (lengthText.length > length) {
      result = `${value.substring(0, length)}...`
    } else {
      result = value
    }

    return result
  }
  return '-'
})

Vue.filter('phone', (value, code, no) => {
  if (value) {
    if (code) {
      return `${code} ${no}`
    }
    return `(62) ${no}`
  }
  return '-'
})
